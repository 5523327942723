import { constantRoutes } from "@/router";
import { getRouters } from "@/api/menu";
import Layout from "@/layout/index";
import ParentView from "@/components/ParentView";
import { toCamelCase } from "@/utils";

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: [], // 左侧边菜单的路由，被 Sidebar/index.vue 使用
    topbarRouters: [], // 顶部菜单的路由，被 TopNav/index.vue 使用
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = constantRoutes.concat(routes);
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes;
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes;
    },
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise((resolve) => {
        // 向后端请求路由数据（菜单）
        getRouters().then((res) => {
          const sdata = JSON.parse(JSON.stringify(res.data)); // 【重要】用于菜单中的数据
          const rdata = JSON.parse(JSON.stringify(res.data)); // 用于最后添加到 Router 中的数据
          const sidebarRoutes = filterAsyncRouter(sdata);
          const rewriteRoutes = filterAsyncRouter(rdata, false, true);
          rewriteRoutes.push({ path: "*", redirect: "/404", hidden: true });
          commit("SET_ROUTES", rewriteRoutes);
          commit("SET_SIDEBAR_ROUTERS", constantRoutes.concat(sidebarRoutes));
          commit("SET_DEFAULT_ROUTES", sidebarRoutes);
          commit("SET_TOPBAR_ROUTES", sidebarRoutes);
          resolve(rewriteRoutes);
        });
      });
    },
  },
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter((route, index) => {
    let newPage =  false;
      if (route.path.indexOf("/newPage") > -1) {
        newPage = true;
        route.path = route.path.replace('/newPage', '')
      }
    // 将 ruoyi 后端原有耦合前端的逻辑，迁移到此处
    // 处理 meta 属性
    // console.log('route.meta.name', route.name);
   let name = route.name;
    route.meta = {
      title: route.name,
      icon: route.icon,
      noCache: !route.keepAlive,
    };
    // 路由地址转首字母大写驼峰，作为路由名称，适配 keepAlive
    route.name = toCamelCase(route.path, true);
    // 处理三级及以上菜单路由缓存问题，将path名字赋值给name
    if (route.path.indexOf("/") !== -1) {
      const pathArr = route.path.split("/");
      route.name = toCamelCase(pathArr[pathArr.length - 1], true);
    }
    route.hidden = !route.visible;

    // 处理一级菜单
    if (route.parentId == 0 && !route.children && !newPage) {
      if (index == 0) {
        route.path = '';
        route.redirect = route.path;
      }
      route.children = [
        {
          path: route.path,
          component: loadView(route.component),
          meta: route.meta,
          name,

        },
      ];
      route.component = Layout;
      return true;
    }

    // 处理 component 属性
    if (route.children) {
      // 父节点
      if (route.parentId === 0) {
        route.component = Layout;
      } else {
        route.component = ParentView;
      }
      // 解决只有一个菜单时无法显示目录
      route.alwaysShow = true;
    } else {
      // 根节点
      route.component = loadView(route.component);
    }

    // filterChildren
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route["children"];
    }
    return true;
  });
}

function filterChildren(childrenMap, lastRouter = false) {
  let children = [];
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (!el.component && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
    }
    children = children.concat(el);
  });
  return children;
}

export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve);
};

export default permission;
